$(document).ready(function (){


    const mainAside = document.getElementById("main-aside");
    const mainMenu = document.getElementById("main-menu");
    const ul = $(mainMenu).children("ul");
    const list = ul.children("li");
    let activeLi = null;
    const toggleButtons = $(".menu-toggle")

    const setActive = (li) => {
        $(li).addClass("active");
        activeLi = li;
    }

    const itemInit = (li) => {
        const sub = $(li).children("ul");
        if (sub.length === 0) return false;

        const a = $(li).children("a");

        $(a).on("click", (e) => {

            if ( activeLi && $(activeLi).hasClass("active") ) {
                $(activeLi).removeClass("active");
            }

            if (activeLi !== li) {
                e.preventDefault()
                e.stopPropagation();
            }
            setActive(li);
        });
    }
    list.each(function (i, li) {
        itemInit(li);
    })
    setActive(list[0]);

    const menuToggle = () => {
        $(mainAside).fadeToggle();
        $("body").toggleClass("fixed-body")
        toggleButtons.toggleClass("open");
    }

    toggleButtons.on("click", (e) => {
        menuToggle();
    })





    const buttonScroll = $(".header-scroll");
    const topBar = $(".main-header-topbar");
    buttonScroll.on("click", function () {
        const h = $(".main-header").height();
        $("html, body").animate({
            scrollTop : h
        }, {
            duration: 500,
        })
    })
    $(window).on("scroll", function (){
        if ($(this).scrollTop() > 0) {
            buttonScroll.css({visibility: "hidden"});
        } else {
            buttonScroll.css({visibility: "visible"});
        }
        if ($(this).scrollTop() > 0){//$(window).height() + 100) {
            topBar.addClass("sticky-topbar");
            $("body").addClass("scrollable");
        } else {
            topBar.removeClass("sticky-topbar");
            $("body").removeClass("scrollable");
        }
    })





    const listBlockInit = (el) => {
        let links = [], blocks = [], active = 0, autoSlide = true, int, maxH = 0;

        const show = (i) => {
            blocks[active].removeClass("active");
            blocks[i].addClass("active");
            links[active].removeClass("active");
            links[i].addClass("active");
            active = i;
        }
        el.find(".list-block-aside>.item").each(function (i){
            const link = $(this);
            links.push(link);
            link.on("click", () => {
                show(i);
                clearInterval(int);
            })
        })
        el.find(".list-block-body>.item").each(function (i){
            maxH = Math.max(maxH, $(this).height())
            blocks.push($(this));
        })
        blocks.forEach(block => {
            block.height(maxH)
        })
        el.click(function (){
            if (int) {
                clearInterval(int);
            }
        })

        int = setInterval(() => {
            const next = active >= links.length - 1 ? 0 : active + 1;
            show(next);
        }, 2000)
    }

    $(".list-block").each(function (){
        const block = $(this);
        listBlockInit(block);
    });


    $(".all-collapsed").each(function (){
        const self = $(this);
        self.on("click", ".more,.toggle", function (){
            self.toggleClass("opened");
        })
    })

    $('body').append('<div class="upbtn"></div>');
    $(window).scroll(function() {
        if ($(this).scrollTop() > 100) {
            $('.upbtn').css({
                bottom: '15px'
            });
        } else {
            $('.upbtn').css({
                bottom: '-80px'
            });
        }
    });
    $('.upbtn').on('click',function() {
        $('html, body').animate({
            scrollTop: 0
        }, 500);
        return false;
    });

    $(".prim-carousel ").owlCarousel({
        items : 1,
        nav: true,
        loop: true,
        autoplay: true,
        autoplayTimeout: 7000
    })

    $(".product-content table").wrap("<div class='table-responsive'></div>");

    $('input.search-input').autoComplete({
        source: function(term, response){
            $.getJSON('/catalog/fast-search', { text: term }, function(data){
                response(data);
            });
        },
        renderItem: function (item, search) {
            console.log(item);
            search = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
            var re = new RegExp("(" + search.split(' ').join('|') + ")", "gi");
            var img = item.pic ? '<img src="'+item.pic+'" height="50" width="50" />' : '';
            return '<div class="autocomplete-suggestion" data-url="'+item.url+'"  data-val="'+search+'"><span class="srow"><span class="pic">' + img + '</span><span class="name">' + item.fullname.replace(re, "<b>$1</b>")+'</span><!--<span class="price">'+(item.price ? item.price+"₽" : "")+'</span>--></span></div>';
        },
        onSelect: function(e, term, item) {
            window.location.href = item.data("url");
        },
        cache : false
    });


    let view = localStorage.getItem("catalogView") || "tile";
    const
        catalogList = $(".catalog-list"),
        btns = {
            list : $("#btn-list"),
            tile : $("#btn-tile")
        }
    changeView(view);
    function changeView(view) {
        catalogList
            .removeClass("view-list")
            .removeClass("view-tile")
            .addClass("view-" + view);
        btns.list.removeClass("active");
        btns.tile.removeClass("active");
        btns[view].addClass("active");
        localStorage.setItem("catalogView", view);
    }
    for (let type in btns) {
        btns[type].on("click", function (){
            changeView(type);
        })
    }



    var owl = {
        big_pics : $(".big-pic-wrapper .img-slider"),
        thumb_pics : $(".thumb-pics-wrapper .img-slider")
    };
    owl.big_pics_owl = owl.big_pics.owlCarousel({
        items : 1,
        dots : false
    });
    owl.thumb_pics_owl = owl.thumb_pics.owlCarousel({
        items : 4,
        dots : false,
        responsive : {
            0 : {
                items : 3
            },
            410 : {
                items : 4
            },
            992 : {
                items : 5
            },
            1200 : {
                items : 6
            }
        }
    });

    $(".pics-next").click(function(){
        owl.big_pics.data("owl.carousel").next();
        owl.thumb_pics.data("owl.carousel").next();
    });
    $(".pics-prev").click(function(){
        owl.big_pics.data("owl.carousel").prev();
        owl.thumb_pics.data("owl.carousel").prev();
    });
    owl.big_pics.on('changed.owl.carousel', function(event) {
        owl.thumb_pics.find(".td").removeClass("active").eq(event.item.index).addClass("active");
    });
    owl.thumb_pics.find(".td").eq(0).addClass("active");
    owl.thumb_pics.find(".td").each(function(i){
        $(this).hover(function(){
            owl.big_pics.data("owl.carousel").to(i, null, true);
        });
    });



})