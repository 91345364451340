function mainHeaderInit(iconsBlock) {

    const toggleButton = iconsBlock.querySelector(".search-go");
    const inputBlock = iconsBlock.querySelector(".search-block");
    const input = inputBlock.querySelector("input[type=text]");
    const clear = inputBlock.querySelector(".input-button-clear");
    const submit = inputBlock.querySelector(".input-button-submit");

    const hide = function () {
        //inputBlock.style.display = "none";
        inputBlock.style.visibility = "hidden";
        inputBlock.style.opacity = 0;
        setTimeout(() => {
            toggleButton.style.display = "inline-block";
        }, 500)
    }
    const show = function () {
        //inputBlock.style.display = "block";
        inputBlock.style.visibility = "visible";
        inputBlock.style.opacity = 1;
        toggleButton.style.display = "none";
        setTimeout(() => {
            input.focus();
        }, 100)

    }
    const hideClear = function () {
        clear.style.display = "none";
    }
    const showClear = function () {
        clear.style.display = "inline-block";
    }
    hide();
    hideClear();
    toggleButton.addEventListener("click", function (e) {
        show();
        e.stopPropagation();
    })
    document.addEventListener('click', e => {
        const target = e.target;
        target !== input ? hide() : false;
    });
    input.addEventListener("input", function (e) {
        if (e.target.value.trim() !== "") {
            showClear();
        } else {
            e.target.value = "";
            hideClear();
        }
    })
    clear.addEventListener("click", function (e) {
        hideClear();
        input.value = "";
        e.stopPropagation();
        input.focus();
    })
    submit.addEventListener("click", function (e) {
        if (input.value.trim() === "") {
            input.value = "";
            e.preventDefault();
        }
    })

}

const iconsBlock = document.getElementById("icons-block");
if (iconsBlock) {
    mainHeaderInit(iconsBlock)
}