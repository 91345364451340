(function (){
    const sidebarId = 'sidebar-mobile';
    const toggleSelector = '.sidebar-toggle'

    const sidebar = document.getElementById(sidebarId);
    const ul = [].slice.call(sidebar.querySelectorAll('ul'))
    const li = [].slice.call(sidebar.querySelectorAll('li'))
    const a  = [].slice.call(sidebar.querySelectorAll('a'))

    ul.forEach(item => {
        item.classList.add('sbm-items')
    })
    li.forEach(item => {
        item.classList.add('sbm-item')
        const sub = item.querySelector('ul')
        if (sub) {
            item.classList.add('sbm-expand')
            sub.classList.add('sbm-expand-content')
            item.querySelector('a').classList.add('sbm-expand-link')
        }
    })
    a.forEach(item => {
        item.classList.add('sbm-link')
    })

    const navExpand = [].slice.call(sidebar.querySelectorAll('.sbm-expand'))
    const closeLink = `<li class="sbm-item">
	<a class="sbm-link sbm-back-link sidebar-toggle" href="javascript:;">
		Закрыть
	</a>
</li>`
    const backLink = `<li class="sbm-item">
	<a class="sbm-link sbm-back-link" href="javascript:;">
		Назад
	</a>
</li>`

    const firstUl = sidebar.querySelector('ul')
    let actives = [];
    firstUl.insertAdjacentHTML('afterbegin', closeLink)

    navExpand.forEach(item => {
        item.querySelector('.sbm-expand-content').insertAdjacentHTML('afterbegin', backLink)

        const link = item.querySelector('.sbm-link');
        link.addEventListener('click', (e) => {
            e.preventDefault();
            if (link.classList.contains('sbm-expand-link')) {
                e.preventDefault()
            }
            item.classList.add('active')
            pushActives(item);
            sidebar.scrollTop = 0;
        })

        item.querySelector('.sbm-back-link').addEventListener('click', () => {
            item.classList.remove('active')
            popActives()
        })
    })

    const pushActives = (item) => {
        if (actives.length > 0) {
            actives[actives.length - 1].classList.remove('sbm-upper')
        }
        item.classList.add('sbm-upper')
        actives.push(item)
    }

    const popActives = () => {
        const removed = actives.pop();
        if (removed) {
            removed.classList.remove('sbm-upper')
        }
        if (actives.length > 0) {
            actives[actives.length - 1].classList.add('sbm-upper')
        }
    }

    pushActives(sidebar)

// ---------------------------------------
// not-so-important stuff starts here

    const toggleButton = [].slice.call(document.querySelectorAll(toggleSelector))
    toggleButton.forEach(item => {
        item.addEventListener('click', function(e) {
            e.preventDefault();
            document.body.classList.toggle('sbm-is-toggled')
        })
    })

})()

